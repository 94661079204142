import ScrollReveal from 'scrollreveal/src/scrollreveal'

//Gform Inputs
function gFormInputs() {
  $('.gfield_error').addClass('selected')
  var fields = $('.gfield input, .gfield textarea')

  fields.each(function() {
    if ($(this).val() !== '') {
      var parent = $(this).closest('.gfield')
      parent.addClass('selected')
    }
  })

  fields.bind('focus', function() {
    var parent = $(this).closest('.gfield')
    parent.addClass('selected')
  })

  fields.on('change', function() {
    var parent = $(this).closest('.gfield')
    parent.addClass('selected')
  })

  fields.bind('focusout', function() {
    var current = $(this)
    var parent = current.closest('.gfield')
    if (current.val() === '' && parent.hasClass('selected')) {
      if (!parent.hasClass('gfield_error')) {
        parent.removeClass('selected')
      }
    }
  })
}

export default {
  init() {
    // JavaScript to be fired on all pages
    gFormInputs()
    /*eslint-disable*/
    ScrollReveal().reveal('.animation_s', {
      duration: 600,
      distance: '20px',
      origin: 'top',
      scale: 1,
      rotate: { x: 0, y: 0, z: 0 },
      delay: 200,
      easing: 'cubic-bezier(0.6, 0.2, 0.1, 1)',
      viewFactor: 0.01,
      reset: false,
    })
    /*eslint-enable*/
    /*eslint-disable*/

    $(window).on('load', function() {
      var screenCenter = $(window).height() / 2
      var ankerH = $('.ankernav').outerHeight()
      var maxLineH = screenCenter + ankerH / 2
      $(window).on('scroll', function() {
        var activeElem = $('.active').attr('id')
        var elem = document.getElementById(activeElem)
        if (elem !== null) {
          var coords = elem.getBoundingClientRect()
          var coordsbottom = coords.bottom
          var linePosition = coordsbottom - maxLineH
          var lineH = linePosition - 30
          $('.aniline').each(function(index, element) {
            var aniline = $(element)
            aniline.find('div').css('height', lineH)
          })
        }
      })
    })

    /*
    $(window).load(function() {
      var screenCenter = $(window).height() / 2
      var ankerH = $('.ankernav').outerHeight()
      var maxLineH = screenCenter + ankerH / 2
      $(window).on('scroll', function() {
        var activeElem = $('.active').attr('id')
        var elem = document.getElementById(activeElem)
        if (elem !== null) {
          var coords = elem.getBoundingClientRect()
          var coordsbottom = coords.bottom
          var linePosition = coordsbottom - maxLineH
          var lineH = linePosition - 30
          $('.aniline').each(function(index, element) {
            var aniline = $(element)
            aniline.find('div').css('height', lineH)
          })
        }
      })
    })
    */
    /*eslint-enable*/

    /*eslint-disable*/
    //Anker navigation START
    $(document).ready(function() {
      var point = $('.anchor_section')
      var count = point.length
      var i = 0
      if (count > 0) {
        $('.dhsv_vc_anker').fadeIn()
        $('#first_section').append('<div class="navpos"/>')
        $('.footer').prepend('<div class="ankernav_ende"/>')
      } else {
        $('.dhsv_vc_anker').remove()
      }
      point.each(function() {
        i++
        var eID = $(this).attr('id')
        var nav = $('.ankernav')
        nav.addClass('hidenav')

        nav
          .find('ul')
          .append(
            '<li class="ankernav-li"><a href="#' +
              eID +
              '" class="ankernav-item" style="position: relative" style="transition-delay: ' +
              i / 50 +
              's"></a></li>'
          )
      })
    })

    $(window).on('scroll', ankerScroll)
    function ankerScroll() {
      var scrolled = $(window).scrollTop()
      var winH = $(window).height()
      var point = $('.anchor_section')
      var nav = $('.ankernav')
      var navPos = $('.navpos')
      var end = $('.ankernav_ende')
      if (end.length) {
        if (
          scrolled + winH / 2 >= navPos.offset().top &&
          scrolled + winH / 2 <= end.offset().top
        ) {
          nav.removeClass('hidenav')
        } else {
          nav.addClass('hidenav')
        }
      } else if (navPos.length) {
        if (scrolled + winH / 2 >= navPos.offset().top) {
          nav.removeClass('hidenav')
        } else {
          nav.addClass('hidenav')
        }
      }

      point.each(function(index) {
        var element = $(this)
        var eID = element.attr('id')
        //console.log(eID)
        var eTop = element.offset().top

        if (
          index !== point.length - 1 &&
          point.eq(index + 1).offset().top <= scrolled + winH / 3
        ) {
          $('[href="#' + eID + '"]')
            .parent()
            .removeClass('anker-active')
          $('section[id*=' + eID + ']').removeClass('active')
        } else {
          if (eTop <= scrolled + winH / 3) {
            $('[href="#' + eID + '"]')
              .parent()
              .addClass('anker-active')
            $('section[id*=' + eID + ']').addClass('active')
          } else {
            $('[href="#' + eID + '"]')
              .parent()
              .removeClass('anker-active')
            $('section[id*=' + eID + ']').removeClass('active')
          }
        }
      })
    } //END Anker Navigation
    /*eslint-enable*/

    $('.anchor-down').click(function(event) {
      event.preventDefault()

      var id = $(this).attr('href'),
        top = $(id).offset().top

      $('body,html').animate({ scrollTop: top - 80 }, 1500)
    })

    // Mobile dropdown
    var $dd = $('.nav .dropdown')
    $dd.on('click tap', function() {
      if ($(this).hasClass('dd-show')) {
        $(this).removeClass('dd-show')
        $(this)
          .children('.dropdown-menu')
          .slideUp('slow')
      } else {
        $dd.removeClass('dd-show')
        $(this).addClass('dd-show')
        $(this)
          .children('.dropdown-menu')
          .slideDown('slow')
      }
    })

    // Mobile dropdown second level
    var $dd_sub = $('.dropdown-menu .dropdown')
    $dd_sub.on('click tap', function() {
      // eslint-disable-next-line no-console
      console.log('dd_sub call')
      if ($(this).hasClass('dd-show-sub')) {
        $(this).removeClass('dd-show-sub')
        $(this)
          .children('.dropdown-menu')
          .slideUp('slow')
      } else {
        $dd_sub.removeClass('dd-show-sub')
        $(this).addClass('dd-show-sub')
        $(this)
          .children('.dropdown-menu')
          .slideDown('slow')
      }
    })

    // $('.hide-content')
    //   .nextAll()
    //   .hide()
    // $('.hide-content')
    //   .closest('.gform_wrapper')
    //   .find('.gform_footer .gform_button')
    //   .hide()
    //
    // $('.hide-content').on('click', function() {
    //   $(this)
    //     .nextAll()
    //     .slideToggle()
    //
    //   $(this)
    //     .closest('.gform_wrapper')
    //     .find('.gform_footer .gform_button')
    //     .slideToggle()
    // })

    $('.matchHeight').matchHeight()
    $('.matchHeight1').matchHeight()

    function headerH() {
      var headerHeight = $('header.banner').outerHeight()
      $('.wrap.container').css('padding-top', headerHeight)
    }

    /*
    $(window).load(function() {
      headerH()
    })
    */

    $(window).on('load', function() {
      headerH()
    })

    function resizeFullWidthRow() {
      $('[data-vc-full-width="true"]').each(function() {
        $(this).css({
          width: document.body.clientWidth,

          marginLeft: -document.body.clientWidth / 2,
        })

        if (!$(this).attr('data-vc-stretch-content')) {
          let containerWidth = $('.container.wrap').outerWidth()

          let padding = (document.body.clientWidth - containerWidth) / 2

          $(this).css({
            paddingLeft: padding,

            paddingRight: padding,
          })
        }
      })
    }

    resizeFullWidthRow()

    $(window).on('resize', function() {
      resizeFullWidthRow()
      headerH()
    })

    //Sticky header
    $(window).scroll(function() {
      if ($(this).scrollTop() > 1) {
        $('header').addClass('sticky')
      } else {
        $('header').removeClass('sticky')
      }
    })

    // Footer sitemap
    $('.footer__sitemap--link').on('click', function() {
      $('.footer__sitemap--link').fadeOut(function() {
        $('.footer__sitemap--link')
          .text(
            $('.footer__sitemap--link').text() == 'close sitemap'
              ? 'open sitemap'
              : 'close sitemap'
          )
          .fadeIn()
      })
      $('.footer__menu').slideToggle('slow')
    })

    // Search icon
    $(document).ready(function() {
      var submitIcon = $('.searchbox-icon')
      var inputBox = $('.searchbox-input')
      var searchBox = $('.searchbox')
      var bodyhtml = $('body,html')
      var wrapcontainer = $('.wrap.container')
      var isOpen = false
      submitIcon.click(function() {
        if (isOpen == false) {
          searchBox.addClass('searchbox-open')
          bodyhtml.addClass('no-scroll')
          inputBox.focus()
          isOpen = true
        } else {
          searchBox.removeClass('searchbox-open')
          bodyhtml.removeClass('no-scroll')
          inputBox.focusout()
          isOpen = false
        }
      })
      submitIcon.mouseup(function() {
        return false
      })
      searchBox.mouseup(function() {
        return false
      })
      wrapcontainer.click(function() {
        searchBox.removeClass('searchbox-open')
        bodyhtml.removeClass('no-scroll')
        isOpen = false
      })
      // $(document).mouseup(function() {
      //   if (isOpen == true) {
      //     $('.searchbox-icon').css('display', 'block')
      //     submitIcon.click()
      //   }
      // })
      searchBox.on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which
        if (keyCode === 13) {
          e.preventDefault()
          return false
        }
      })
      searchBox.submit(function(e) {
        e.preventDefault()
      })
    })

    var inputVal = $('.searchbox-input').val()
    inputVal = $.trim(inputVal).length
    if (inputVal !== 0) {
      $('.searchbox-icon').css('display', 'none')
    } else {
      $('.searchbox-input').val('')
      $('.searchbox-icon').css('display', 'block')
    }

    //add link to borlabs cookie box
    $(document).ready(function() {
      addlink()
      modify_footer_link()
      hide_empty_language_switcher()
      custom_ie_functions()
    })

    function modify_footer_link() {
      // eslint-disable-next-line no-console
      //console.log('modify_footer_link')
      $('#menu-item-10039')
        .find('a')
        .addClass('borlabs-cookie-preference')
    }

    function addlink() {
      // eslint-disable-next-line no-console
      //console.log('addlink function call')
      const homeUrl = window.location.origin
      $('._brlbs-legal')
        .children('span')
        .eq(1)
        .append(`<a href="${homeUrl}/cookie-notice/">Cookie Notice</a>`)
      $('._brlbs-legal')
        .children('span')
        .eq(1)
        .append('<span class="_brlbs-separator"></span>')
    }

    function hide_empty_language_switcher() {
      var switcher_no_children = $(
        '.wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu'
      ).children().length

      // eslint-disable-next-line no-console
      //console.log('Length: ' + switcher_no_children)

      if (switcher_no_children < 1) {
        $('header.banner .language_dropdown').hide()
      }
    }

    function custom_ie_functions() {
      // eslint-disable-next-line
      if (navigator.userAgent.match(/Trident.*rv\:11\./)) {
        $('body').addClass('browser-ie11')
        $('header.banner ul.nav > li').on('mouseover', function() {
          $(this).addClass('showsubmenu')
        })
        $('header.banner ul.nav > li').on('mouseout', function() {
          var $this = $(this)
          setTimeout(function() {
            $this.removeClass('showsubmenu')
          }, 2000)
        })
      }
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

jQuery(document).bind('gform_post_render', function() {
  if (typeof gFormInputs == 'function') {
    gFormInputs()
  }
})
